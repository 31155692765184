import React, { useState, useEffect, useRef } from "react"
import {
  FacebookShareButton,
  FacebookIcon,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  LinkedinShareButton,
  LinkedinIcon,
  RedditShareButton,
  RedditIcon,
  TelegramShareButton,
  TelegramIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share"
import containerStyles from "./index.module.css"

import { sendIcon, gmailIcon, outlookIcon, closeIcon } from "../../images/svgs"

// TODO
// Adjust the campaign form component to accept and editable? prop
// then reuse it on this page?

interface Props {
  campaignData: Campaign
}

type ModalStateType = false | "Gmail" | "Outlook"

let isIOS: boolean
let windowNavigator: Navigator

const EmailList: React.FC<{ list: string[] }> = ({ list }) => (
  <>
    {list.map(addr => (
      <span className={containerStyles.emailBox} key={addr}>
        {addr}
      </span>
    ))}
  </>
)

const SendButton: React.FC<{
  label: string
  icon: string
  action: () => void
}> = ({ label, icon, action }) => (
  <button type="button" className={containerStyles.sendButton} onClick={action}>
    <img src={icon} alt="Send" />
    <span>{label}</span>
  </button>
)

const ShareBox: React.FC<{
  id: string
}> = ({ id }) => {
  const shareURLInput = useRef(null)
  const [copied, setCopied] = useState(false)

  const selectAll = ({ current: ref }) => {
    ref.select()
    ref.setSelectionRange(0, 99999)
  }

  const copy = ({ current: ref }) => {
    selectAll({ current: ref })
    document.execCommand("copy")
    setCopied(true)
  }

  return (
    <span
      className={`${containerStyles.shareBox} ${
        copied ? containerStyles.copied : ""
      }`}
    >
      <input
        ref={shareURLInput}
        type="text"
        name="shareLink"
        value={`https://voiceforward.org/s/${id}`}
        aria-label="Shareable URL"
        onClick={() => selectAll(shareURLInput)}
        readOnly
      />
      <button type="button" onClick={() => copy(shareURLInput)}>
        {copied ? "Copied ✓" : "Copy"}
      </button>
    </span>
  )
}

const ShareButtons: React.FC<{
  id: string
  subject: string
}> = ({ id, subject }) => {
  const uri = `/s/${id}`
  const url = `voiceforward.org${uri}`
  const iconSize = 32
  const iconBR = 5
  const [showShareButtons, setShowShareButtons] = useState(false)

  const share = async () => {
    try {
      await windowNavigator.share({
        title: `${subject}`,
        text:
          "You've been invited to forward this email with VoiceForward! 🗣➡️",
        url: uri,
      })
    } catch {
      setShowShareButtons(!showShareButtons)
    }
  }

  return (
    <div
      className={
        showShareButtons ? containerStyles.shareButtonsContainerShown : ""
      }
    >
      <button
        type="button"
        onClick={share}
        className={containerStyles.shareButton}
      >
        Share
      </button>
      {showShareButtons && (
        <div className={containerStyles.shareButtonsContainer}>
          <ShareBox id={id} />
          <div className={containerStyles.shareButtonsContainerFlex}>
            <TwitterShareButton url={url}>
              <TwitterIcon size={iconSize} borderRadius={iconBR} />
            </TwitterShareButton>
            <FacebookShareButton url={url}>
              <FacebookIcon size={iconSize} borderRadius={iconBR} />
            </FacebookShareButton>
            <FacebookMessengerShareButton url={url} appId="692556811307224">
              <FacebookMessengerIcon size={iconSize} borderRadius={iconBR} />
            </FacebookMessengerShareButton>
            <LinkedinShareButton url={url}>
              <LinkedinIcon size={iconSize} borderRadius={iconBR} />
            </LinkedinShareButton>
            <RedditShareButton url={url}>
              <RedditIcon size={iconSize} borderRadius={iconBR} />
            </RedditShareButton>
            <TelegramShareButton url={url}>
              <TelegramIcon size={iconSize} borderRadius={iconBR} />
            </TelegramShareButton>
            <WhatsappShareButton url={url}>
              <WhatsappIcon size={iconSize} borderRadius={iconBR} />
            </WhatsappShareButton>
          </div>
        </div>
      )}
    </div>
  )
}

function clientButtonLogic(
  isTooLong: boolean,
  nativeLink: string,
  websiteLink: string,
  appCallback: () => void
): void {
  isIOS
    ? isTooLong
      ? (window.location.href = nativeLink)
      : appCallback()
    : window.open(websiteLink, "_blank")
}

const scrollToRef = ref => ref.current.scrollIntoView({ behavior: "smooth" })

export const CampaignDetails: React.FC<Props> = ({ campaignData }) => {
  const { CampaignId: id, to, subject, body } = campaignData
  const cc = campaignData.cc ? campaignData.cc : []

  const mailtoLink = encodeURI(
    `mailto:${to}?cc=${cc}&subject=${subject}&body=${body}`
  )

  const gmailLink = encodeURI(
    `https://mail.google.com/mail/?view=cm&fs=1&to=${to}&cc=${cc}&su=${subject}&body=${body}`
  )

  const gmailTooLong = gmailLink.length > 3190

  const nativeGmailLink = encodeURI(
    `googlegmail://co?to=${to}&cc=${cc}&subject=${subject}&body=${body}`
  )

  const outlookLink = encodeURI(
    `https://outlook.live.com/?path=/mail/action/compose&to=${[
      ...to,
      ...cc,
    ]}&subject=${subject}&body=${body}`
  )

  const outlookTooLong = outlookLink.length > 1670

  const nativeOutlookLink = encodeURI(
    `ms-outlook://compose?to=${to}&cc=${cc}&subject=${subject}&body=${body}`
  )

  const sendActions = useRef(null)
  const scrollToSendActions = () => scrollToRef(sendActions)
  const [modalState, setModalState] = useState<ModalStateType>(false)
  const [isAtBottom, setIsAtBottom] = useState<boolean>(false)
  let currentIcon, currentAppLink, currentWebLink

  if (modalState === "Gmail") {
    currentIcon = gmailIcon
    currentAppLink = nativeGmailLink
    currentWebLink = gmailLink
  } else if (modalState === "Outlook") {
    currentIcon = outlookIcon
    currentAppLink = nativeOutlookLink
    currentWebLink = outlookLink
  }

  const handleScroll = () => {
    if (
      window.innerHeight + window.scrollY >=
      document.body.clientHeight - 150
    ) {
      setIsAtBottom(true)
    } else {
      setIsAtBottom(false)
    }
  }

  useEffect(() => {
    isIOS =
      navigator &&
      navigator.platform &&
      /iPad|iPhone|iPod/.test(navigator.platform)
    windowNavigator = navigator
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  return (
    <>
      <span>{modalState}</span>
      <div
        className={`${containerStyles.modal} ${
          !modalState && containerStyles.modalHidden
        }`}
      >
        <div
          className={containerStyles.modalBackdrop}
          onClick={() => setModalState(false)}
        />
        <div className={containerStyles.modalContent}>
          <button
            className={containerStyles.closeButton}
            onClick={() => setModalState(false)}
          >
            <img src={closeIcon} alt="Close" />
          </button>
          <h2>Do you have the {modalState} app installed?</h2>
          <SendButton
            label={`Send with ${modalState} App`}
            icon={currentIcon}
            action={() => (window.location.href = currentAppLink)}
          />
          <p className={containerStyles.ifNotDivider}>if not</p>
          <SendButton
            label={`Send with ${modalState} Website`}
            icon={currentIcon}
            action={() => window.open(currentWebLink, "_blank")}
          />
        </div>
      </div>
      <div className={containerStyles.headerField}>
        <div className={containerStyles.flexFieldLabel}>To: </div>
        <div className={containerStyles.flexFieldValue}>
          <EmailList list={to} />
        </div>
      </div>
      {cc.length > 0 && (
        <div className={containerStyles.headerField}>
          <div className={containerStyles.flexFieldLabel}>CC: </div>
          <div className={containerStyles.flexFieldValue}>
            <EmailList list={cc} />
          </div>
        </div>
      )}
      <div
        className={`${containerStyles.headerField} ${containerStyles.subjectField}`}
      >
        <div className={containerStyles.flexFieldLabel}>Subject: </div>
        <div className={containerStyles.flexFieldValue}>{subject}</div>
      </div>
      <p className={containerStyles.bodyField}>{body}</p>
      <div className={containerStyles.actions}>
        <div className={containerStyles.actionsShare}>
          <ShareButtons id={id} subject={subject} />
        </div>
        <div className={containerStyles.actionsSend} ref={sendActions}>
          <div
            className={`${containerStyles.actionsSendMain} ${
              (!gmailTooLong || !outlookTooLong || isIOS) &&
              containerStyles.actionsSendMainSeparator
            }`}
          >
            <SendButton
              label="Send"
              icon={sendIcon}
              action={() => (window.location.href = mailtoLink)}
            />
          </div>
          <div className={containerStyles.actionsSendOther}>
            {(!gmailTooLong || isIOS) && (
              <SendButton
                label="Send with Gmail"
                icon={gmailIcon}
                action={() =>
                  clientButtonLogic(
                    gmailTooLong,
                    nativeGmailLink,
                    gmailLink,
                    () => setModalState("Gmail")
                  )
                }
              />
            )}
            {(!outlookTooLong || isIOS) && (
              <SendButton
                label="Send with Outlook"
                icon={outlookIcon}
                action={() =>
                  clientButtonLogic(
                    outlookTooLong,
                    nativeOutlookLink,
                    outlookLink,
                    () => setModalState("Outlook")
                  )
                }
              />
            )}
          </div>
        </div>
      </div>
      <div
        className={`${containerStyles.stickyPrompt} ${
          isAtBottom && containerStyles.stickyPromptHidden
        }`}
      >
        <button
          type="button"
          onClick={scrollToSendActions}
          aria-label="Skip to actions"
        >
          <span>Send Now!</span>
          <img src={sendIcon} alt="Send" />
        </button>
      </div>
    </>
  )
}

export default CampaignDetails
