import React, { useState, useEffect } from "react"
import { PageProps, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Loader from "../components/loader"
import CampaignDetails from "../components/campaign-details"

const apiEndpoint =
  "https://djo8fuit2l.execute-api.us-west-2.amazonaws.com/emailAppMicroservice?CampaignId="

const Homepage: React.FC<PageProps> = ({ location: { search } }) => {
  const [campaignData, setCampaignData] = useState({} as Campaign)
  const [error, setError] = useState(false)

  const id = new URLSearchParams(search).get("id")
  const idValid = !/[^a-z]/i.test(id)

  useEffect(() => {
    idValid
      ? fetch(apiEndpoint + id)
          .then(response => response.json())
          .then(({ Items: items, Count: count }) => {
            if (count > 0) {
              setCampaignData(items[0])
            } else {
              throw "No results found."
            }
          })
          .catch(() => setError(true))
      : setError(true)
  }, [search])

  return (
    <Layout>
      {error ? (
        <>
          <h3>Sorry... That email wasn't found...</h3>
          <p>
            <Link to="/">Create a new campaign!</Link>
          </p>
        </>
      ) : Object.keys(campaignData).length > 0 ? (
        <>
          <SEO title={campaignData.subject} />
          <h2
            className="text-center"
            style={{
              paddingBottom: "1rem",
              borderBottom: "0.1rem solid #aaa",
            }}
          >
            You have been invited to forward this email:
          </h2>
          <CampaignDetails campaignData={campaignData} />
        </>
      ) : (
        <>
          <SEO title="Send this email!" />
          <Loader show={true} />
        </>
      )}
    </Layout>
  )
}
export default Homepage
